.marker-cluster-xl {
    background-color: rgba(218, 94, 94, 0.6);
}

.marker-cluster-xl div {
    background-color: rgba(226, 36, 36, 0.6);
}

.marker-cluster-l {
    background-color: rgba(253, 156, 115, 0.6);
}

.marker-cluster-l div {
    background-color: rgba(241, 128, 23, 0.6);
}

.marker-cluster-m {
    background-color: rgba(241, 211, 87, 0.6);
}

.marker-cluster-m div {
    background-color: rgba(240, 194, 12, 0.6);
}

.marker-cluster-s {
    background-color: rgba(205, 253, 115, 0.6);
}

.marker-cluster-s div {
    background-color: rgba(30, 241, 23, 0.6);
}

.marker-cluster {
    background-clip: padding-box;
    border-radius: 20px;
}

.marker-cluster div {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-top: 5px;

    text-align: center;
    text-decoration-color: rgb(250, 250, 250);
    border-radius: 15px;
    font: 12px 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.marker-cluster span {
    line-height: 30px;
}
